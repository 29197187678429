@tailwind base;
@tailwind components;
@tailwind utilities;

.lab_full {
  @apply bg-[url(images/lab2.avif)];
}

.lab_full_m {
  @apply bg-[url(images/lab2.avif)] bg-fixed;
}

.lab {
  @apply bg-[url(images/lab11.avif)] bg-contain;
}

.factory_full {
  @apply bg-[url(images/factory_full4.avif)];
}
.factory_full_m {
  @apply bg-[url(images/factory_full4.avif)] bg-fixed;
}
.factory {
  @apply bg-[url(images/factory3.png)] bg-contain;
}

.parallax_full {
  @apply bg-[url(images/image-full.avif)] bg-fixed;
}

.parallax_center {
  @apply bg-[url(images/image-center.avif)] bg-no-repeat;
}
.parallax_center2 {
  @apply bg-[url(images/image-center2.avif)] bg-no-repeat;
}
.parallax_bottom {
  @apply bg-[url(images/image-bottom.avif)] bg-no-repeat;
}

.contact_img {
  @apply bg-[url(images/contact.png)] bg-no-repeat bg-cover;
}
.dark .beams_big {
  @apply bg-[url(images/bg/big.png)] bg-top bg-repeat-y;
}

.footer_img {
  @apply bg-[url(images/footer.avif)] bg-cover bg-no-repeat;
}
.beams_bigM {
  @apply bg-[url(images/bg/BigB.png)] bg-top bg-repeat-y bg-cover;
}

.content {
  content-visibility: auto;
}

.overflow-animation {
  position: relative;
  animation: leftright 7s infinite alternate ease-in-out;
}

@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.dark .beams_bigM {
  @apply bg-[url(images/bg/BigB.png)] bg-top bg-repeat-y bg-cover;
}
.dark .gr {
  background: radial-gradient(circle, rgba(154, 115, 255, 0.4009978991596639) 0%, rgba(0, 0, 0, 1) 51%);
}
.gr {
  background: radial-gradient(circle, rgba(183, 155, 255, 0.4598214285714286) 0%, rgba(255, 255, 255, 1) 40%);
}

.h {
  @apply py-3 text-lg leading-8 font-bold;
}
.h2 {
  @apply text-2xl font-bold pt-9 leading-8;
}
.p {
  @apply py-3 text-lg font-normal leading-8;
}

.zoom:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.active {
  font-weight: 700;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
